import http from "./httpService";

async function getStockings(accountId) {
	const { data: stockings } = await http.get("accounts/" + accountId + "/stockings");
	return stockings;
}

async function getStockingInfo(accountId, stockingId) {
	const { data: info } = await http.get("accounts/" + accountId + "/stockings/" + stockingId + "/info");
	return info;
}

async function updateStocking(accountId, stockingId, changes) {
	const { data: stocking } = await http.patch("accounts/" + accountId + "/stockings/" + stockingId, changes);
	return stocking;
}

async function createStocking(accountId, stockingRequest) {
	const { data: stocking } = await http.post("accounts/" + accountId + "/stockings", stockingRequest);
	return stocking;
}

async function deleteStocking(accountId, stockingId) {
	return http.delete("accounts/" + accountId + "/stockings/" + stockingId);
}

function handleExpectedErrors(ex, component) {
	http.handleExpectedErrors(ex, component);
}

const stockingService = {
	getStockings,
	getStockingInfo,
	updateStocking,
	createStocking,
	deleteStocking,
	handleExpectedErrors,
};

export default stockingService;
